import React from 'react';
import ReactDOM from 'react-dom';

import { AppContainer } from './AppContainer';

import reportWebVitals from './reportWebVitals';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(<AppContainer />, document.getElementById('root'));

reportWebVitals();
