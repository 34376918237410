const constants = {
    PAYMENT_RESULT: 'paymentResult',

    STATUS: {
        NONE: 'none',
        SUCCESS: 'success',
        FAILURE: 'failure',
    },

    LOADING_STATUS: {
        NONE: 'none',
        LOADING: 'loading',
        DONE: 'done',
    },

    TIME_DELAY: {
        LONG: 3 * 1000,
        SHORT: 250,
    },
    IS_MOBILE: 'isMobile',
    REDIRECT_URL: 'redirectUrl',
    STRIPE_URL: 'https://checkout.stripe.com/',
    PLISIO_URL: 'https://plisio.net/',
};

export default constants;
