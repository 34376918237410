import React from 'react';

import { ContextProvider } from './contexts/Context';
import App from './App';

export const AppContainer = () => {
    return (
        <ContextProvider>
            <App />
        </ContextProvider>
    );
};
