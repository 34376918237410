import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';
import { ToastContext } from '../../contexts/ToastContext';
import './SignatureModal.css';

export const SignatureModal = ({ isOpen, close, username = '', userEmail = '', userMember1Name = '' }) => {
    const [name, setName] = useState(username);
    const [email, setEmail] = useState(userEmail);
    const [member1Name, setMember1Name] = useState(userMember1Name);

    const { notify } = useContext(ToastContext);
    const canvasRef = useRef();

    useEffect(() => {
        setName(username);
        setEmail(userEmail);
        setMember1Name(userMember1Name);
    }, [username, userEmail, userMember1Name]);

    const handleChange = (setState) => (ev) => setState(ev.target.value);

    const onSave = useCallback(() => {
        if (canvasRef.current.isEmpty()) {
            notify('Please sign first.');
            return;
        }

        if ([name, email, member1Name].some((el) => !el.trim())) {
            notify('Please complete all required fields.');
            return;
        }

        if (name.length > 256) {
            notify('Name is too long.');
            return;
        }

        const signatureInBase64 = canvasRef.current.getCanvas().toDataURL();
        close(signatureInBase64, { name, email, member1Name });
    }, [close, name, email, member1Name, notify]);

    const onClear = () => canvasRef.current.clear();

    return (
        <Modal isOpen={isOpen} toggle={close} className="signature-modal" contentClassName="signature-modal-content">
            <ModalHeader toggle={close}>Sign Membership Agreement</ModalHeader>
            <ModalBody>
                <p className='description'>Please complete all required fields and sign in the gray signature box</p>
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">Name:</Label>
                                <Input type="text" name="name" id="name" placeholder="First Name" value={name} onChange={handleChange(setName)} readOnly />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="memberName">Wallet:</Label>
                                <Input type="text" name="memberName" id="memberName" placeholder="Last Name" value={member1Name} onChange={handleChange(setMember1Name)} readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col>
                            <FormGroup>
                                <Label for="email">Email:</Label>
                                <Input type="email" name="email" id="email" placeholder="Email" value={email} onChange={handleChange(setEmail)} readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        <SignatureCanvas
                            ref={canvasRef}
                            penColor="black"
                            clearOnResize={false}
                            canvasProps={{ className: 'sign-canvas', onMouseDown: () => document.activeElement?.blur(), onTouchStart: () => document.activeElement?.blur() }}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSave}>SAVE</Button>
                <Button color="danger" onClick={onClear}>CLEAR</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SignatureModal;