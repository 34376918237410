/**
 *  High Order Function which handles http response
 * @param fn function that makes the http request
 * @returns array which is consisted of response and error [response, error]
 */
export function httpResponseDecorator(fn) {
    return async function () {
        try {
            const response = await fn.apply(this, arguments);
            return [response, null];
        } catch (err) {
            const { response } = err;
            return [
                null,
                {
                    status: response?.status,
                    message: response?.data?.error,
                },
            ];
        }
    };
}

/**
 * Generate query string with the given object by using keys and values
 * @param obj javascript plain object *key-value pairs*
 * @returns query string
 */
export function generateQueryString(obj) {
    const str = Object.entries(obj)
        .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
        .join('&');

    return str;
}
