import axios from 'axios';
import axiosRetry from 'axios-retry';

const retryDelay = (retryNumber = 0) => {
    const seconds = Math.pow(2, retryNumber) * 1000;
    const randomMs = 1000 * Math.random();
    return seconds + randomMs;
};

const ESignApi = axios.create({
    baseURL: process.env.REACT_APP_E_SIGN_API_URL,
});

axiosRetry(ESignApi, {
    retries: 2,
    retryDelay,
    retryCondition: axiosRetry.isRetryableError,
});

export { ESignApi };
