import React, { createContext, useReducer } from "react";

const initialState = {
  userInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  }
};

const StateContext = createContext();
const DispatchContext = createContext();

const ActionTypes = {
  SET_USER_INFO: "set_user_info",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_INFO:
      return { ...state, userInfo: action.payload };
    default:
      return state;
  }
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export { StateContext, DispatchContext, ContextProvider, ActionTypes };
