import { ESignApi } from './Api';
import { httpResponseDecorator } from '../utils/utils';

function getProfile(email) {
    return ESignApi.get('/users', {
        params: { email },
    });
}

function generateTokenForSign(email) {
    return ESignApi.get('/sign/token', {
        params: {
            email,
        },
    });
}

function signDocument({ token, payload }) {
    return ESignApi.post('/sign', payload, {
        headers: {
            authorization: token,
        },
        responseType: 'arraybuffer',
    });
}

function getPaymentUrl({ token, email, name }) {
    return ESignApi.post(
        '/pay',
        { email, name },
        {
            headers: {
                Authorization: token,
            },
        }
    );
}

function getCryptoPaymentUrl({ token, email, name, displayCurrency, tradeCurrency, amount, orderName, orderNum }) {
    return ESignApi.post(
        '/crypto',
        { email, name, tradeCurrency, displayCurrency, amount, orderName, orderNum },
        {
            headers: {
                Authorization: token,
            },
        }
    );
}

function getPole(token) {
    return ESignApi.get('/poling', {
        params: { token },
    });
}

export const ESignService = {
    getProfile: httpResponseDecorator(getProfile),
    signDocument: httpResponseDecorator(signDocument),
    generateTokenForSign,
    getPaymentUrl: httpResponseDecorator(getPaymentUrl),
    getCryptoPaymentUrl: httpResponseDecorator(getCryptoPaymentUrl),
    getPole,
};
